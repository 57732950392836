@tailwind base;
@tailwind components;
@tailwind utilities;

img {
  max-width: 100%;
  height: auto;
  display: block; /* remove extra space below the image */
}

@media (max-width: 768px) {
  img {
    height: auto; /* maintain aspect ratio */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  img {
    height: 50vh; /* set image height to 50% of the viewport height */
  }
}

.responsive-img {
  max-width: 100%;
  height: auto;
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header-animate {
  animation: fadeIn 5s ease-in-out;
}

.pop-animate {
  animation: fadeIn 3s ease-in-out;
}

.shadow-photo {
  box-shadow: 0 10px 50px #e5e9ec;
}

.book-container {
  perspective: 1000px;
  width: 250px; /* Adjust to your image size */
  height: 350px; /* Adjust to your image size */
  cursor: pointer;
}

.book {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 5s;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
}

.book img {
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.book img.back {
  transform: rotateY(180deg);
}

.book:hover {
  transform: rotateY(-360deg);
}
